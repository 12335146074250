/**
 * 天地图地图Api
 */
// 初始化map
let TDTMap = (eleName) => {
    let zoom = 12
    let map = new T.Map(eleName, {
        projection: 'EPSG:4326',
        minZoom: 3,
        maxZoom: 18,
        center: [107.601048,37.594976],
        zoom: 12
    })
    map.disableDoubleClickZoom()
    map.centerAndZoom(new T.LngLat(107.601048,37.594976), zoom)
    var control = new T.Control.Zoom()
    map.addControl(control)
    control.setPosition(T_ANCHOR_BOTTOM_RIGHT)
    return map
}
// 标记
let TDTMarker = (map,longitude,latitude,staffName) => {
    //创建图片对象
    let icon = new T.Icon({
        iconUrl: require("../../img/online.png"),
        iconSize: new T.Point(50, 50),
        iconAnchor: new T.Point(0, 0)
    });
    let marker = new T.Marker(new T.LngLat(longitude, latitude), {icon: icon});// 创建标注
    map.addOverLay(marker);
    let label = new T.Label({
        text: `
            <div class='online-info'>
                <div class='online-info-data'>
                    <div class='info-data-item'>
                        <div>
                            <i class='iconfont icon-yali'></i>
                            <span>压力：</span>
                        </div>
                        <div>
                            <span class='data'>0.36 </span>
                        </div>
                        <div>
                            <span>Mpa</span>
                        </div>
                    </div>    
                    <div class='info-data-item'>
                        <div>
                            <i class='iconfont icon-liuliang'></i>
                            <span>流量：</span>
                        </div>
                        <div>
                            <span class='data'>60 </span>
                        </div>
                        <div>
                            <span>m³/h</span>
                        </div>
                    </div>   
                </div>
                <div class='online-name'>${staffName}</div>    
            </div>
        `,
        position: marker.getLngLat(),
        offset: new T.Point(0, 0)
    });
    map.addOverLay(label);
    return marker
}
// 动态创建线流
let TDTPolyline = (coordinates,item) => {
    let polyline = new T.Polyline(coordinates,{
        color: item.color,
        weight: 4,
        opacity: 1,
        lineStyle: "solid",
    })
    polyline.type = item.pipelineCategoryId
    return polyline
}
// 开始绘制管道
let TDTPolylineTool = (map) => {
    let mouseTool = new T.PolylineTool(map,{
        color: "#4C9EF9", 
        weight: 4,
        opacity: 1,
        lineStyle: "solid",// 线样式还支持 'dashed'
    })
    return mouseTool
}

export default {
    TDTMap: TDTMap,
    TDTMarker: TDTMarker,
    TDTPolyline: TDTPolyline,
    TDTPolylineTool: TDTPolylineTool,
}