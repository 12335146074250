/**
 * 高德地图Api
 */
// 初始化map
let GDMap = (eleName) => {
    let map = new AMap.Map(eleName, {
        resizeEnable: true,
        center: [107.601048,37.594976],
        zooms: [3, 20],
        // showLabel: false,
        viewMode: '3D',
        mapStyle: 'amap://styles/light',
        pitch: 0,
        rotation: 0,
        skyColor: '#F2F2F2',
        features: ['bg','road','building'],
        expandZoomRange: true,
        zoom: 12, //地图显示的缩放级别
        doubleClickZoom: false,
    })
    map.addControl(GDToolBar({
        liteStyle:true,
        position: {
            right: '10px',
            bottom: '70px'
        }
    }))
    return map
}
// 添加工具条
let GDToolBar = (params) => {
    let toolBar = new AMap.ToolBar(params)
    return toolBar
}
// 标记
let GDMarker = (map,longitude,latitude) => {
    let markers = new AMap.Marker({
        map: map,
        position: [longitude, latitude],
        icon: require("../../img/online.png"),
        offset: new AMap.Pixel(0, 0),
        autoRotation: true
    });
    return markers
}
// 动态创建线流
let GDPolyline = (coordinates,item) => {
    let polyline = new AMap.Polyline({
        path: coordinates,
        strokeColor: item.color,
        strokeOpacity: 1,
        strokeWeight: 4,
        strokeStyle: "solid",
        lineJoin: 'round',
        lineCap: 'round',
        zIndex: 50,
        cursor: 'pointer'
    })
    polyline.type = item.pipelineCategoryId
    return polyline
}
// 开始绘制管道
let GDMouseTool = (map) => {
    let mouseTool = new AMap.MouseTool(map)
    mouseTool.polyline({
        strokeColor: "#4C9EF9", 
        strokeOpacity: 1,
        strokeWeight: 4,
        strokeStyle: "solid",// 线样式还支持 'dashed'
        // strokeStyle是dashed时有效
        // strokeDasharray: [10, 5],
    })
    return mouseTool
}

export default {
    GDMap: GDMap,
    GDMarker: GDMarker,
    GDPolyline: GDPolyline,
    GDMouseTool: GDMouseTool,
}